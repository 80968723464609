<template>
    <page :loading="loading">
        <div slot="head" class="flex-col">
            <!-- <div class="flex-item flex-fill" style="text-align: center;">
                <el-input v-model="table.search.keyword" placeholder="请输入搜索关键字" style="width:300px" @keydown.enter.native="LoadDatas()">
                    <el-button slot="append" icon="el-icon-search" @click="LoadDatas()" />
                </el-input>
            </div> -->
        </div>

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" />
            <el-table-column prop='user_id'                        label='用户ID' align='center' />
            <el-table-column prop='out_trade_no'                   label='订单号' align='center' />
			<el-table-column :formatter="typeFormat"   		       label='类型' align='center' prop='type'/>
            <el-table-column prop='integral'                       label='知识币' align='center' />
            <el-table-column prop='give_integral'                  label='赠送知识币' align='center' />
            <el-table-column prop='sum_integral'                   label='到账知识币' align='center' />
            <el-table-column :formatter="statusFormat"   		   label='状态' align='center' prop='status'/>
            <el-table-column prop='created_at'                     label='创建时间' align='center' />
            <el-table-column prop='updated_at'                     label='更新时间' align='center' />
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="table.form = Object.assign({},scope.row),$refs['page-dialog'].open()" type="text" size="small">详情</el-button>
                </template>
            </el-table-column>
        </page-table>

        <page-dialog ref="page-dialog" :loading="loading">
            <el-form ref="form">
                <el-form-item label="用户ID">
                    <el-input v-model="table.form.user_id" />
                </el-form-item>
                <el-form-item label="订单号">
                    <el-input v-model="table.form.out_trade_no"/>
                </el-form-item>
				<el-form-item label="类型">
					<el-select disabled @change="selectType" v-model="table.form.type">
						<el-option label="微信支付" :value="0" />
						<el-option label="支付宝支付" :value="1" />
					</el-select>
				</el-form-item>
                <el-form-item label="知识币">
                    <el-input v-model="table.form.integral" />
                </el-form-item>
                <el-form-item label="赠送知识币">
                    <el-input v-model="table.form.give_integral" />
                </el-form-item>
                <el-form-item label="到账知识币">
                    <el-input v-model="table.form.sum_integral"/>
                </el-form-item>
                <el-form-item label="状态">
                	<el-select disabled v-model="table.form.status">
                		<el-option label="未支付" :value="0" />
                		<el-option label="已完成" :value="1" />
                	</el-select>
                </el-form-item>
            </el-form>
            <!-- <div slot="footer">
                <el-button type="primary" @click="ActionSave(table.form)">保存</el-button>
            </div> -->
        </page-dialog>
    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
                }
            },
			statusList:['未支付','已完成'],
			typeList:['微信支付','支付宝支付'],
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
		//状态格式化
		statusFormat(row) {
		  return this.statusList[row.status];
		},
		//类型格式化
		typeFormat(row) {
			return this.typeList[row.type];
		},
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('app-recharge-record',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

        /**
         * 保存
         * @param item 要保存的数据
         * **/
        ActionSave(item){
            this.loading = true;
            this.$save('app-recharge-record',item).then(res=>
            {
                this.loading = false , this.LoadDatas(),this.$notify({message: res.msg || '操作成功'}) , this.$refs['page-dialog'].close();
            }).catch(err=>
            {
                this.loading = false , this.$notify.error({message: err || '网络请求错误'});
            });
        },
    },
}
</script>